import jwt from 'jsonwebtoken';
import { fetch } from '../../utils/fetch';

const decodeToken = (token) => {
  try {
    if (token) {
      const decoded = jwt.decode(token, { complete: true });
      return decoded.payload;
    }
    return null;
  } catch (e) {
    return e;
  }
};

// actions
const toLogin = (username, password) => (dispatch) => {
  dispatch(onLoading(true));

  return new Promise((resolve, reject) => {
    fetch({
      method: 'post',
      url: '/auth/signin',
      data: { username, password },
    })
      .then(async (res) => {
        if (res.data.success) {
          const { token } = res.data.data;
          window.localStorage.setItem(
            `authen-token-${process.env.REACT_APP_ENV}`,
            token,
          );

          const decode = decodeToken(token);
          console.log("decode1", decode)

          window.localStorage.setItem(
            `user-token-${process.env.REACT_APP_ENV}`,
             decode.userToken,
          );

          dispatch(onLogin(decode.name,
            decode.permission,
            decode.userLevel,
            decode?.companyName || 'RTT Express',
            decode.roleLevel,
            decode?.companyId || '',
            decode?.hubId || '',
            decode?.agencyId || '',
            decode?.userId,
            decode?.isCustomer || false,
            decode.isFirstLogin,
            decode?.agencyCode || '',
            decode?.hubCode || '',
            decode?.hubPhoneNo || ''));

          console.log("decode2", decode)
          dispatch(onVerify({ auth: true, isLoading: false }));
          dispatch(onLoading(false));
          resolve(true);
        } else {
          dispatch(onLoading(false));
          reject(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

const updateFirstPassword = (newPassword) => (dispatch) => {
  dispatch(onLoading(true));

  return new Promise((resolve, reject) => {
    fetch({
      method: 'put',
      url: '/users/me/change-password',
      data: newPassword,
    })
      .then(async (res) => {
        if (res.data.success) {
          window.localStorage.removeItem(
            `authen-token-${process.env.REACT_APP_ENV}`,
          );
          dispatch(onVerify({ auth: false, isLoading: false }));
          dispatch(onLoading(false));
          resolve(true);
        } else {
          dispatch(onLoading(false));
          reject(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

const toLogout = () => (dispatch) => {
  dispatch(onLoading(true));
  window.localStorage.removeItem(`authen-token-${process.env.REACT_APP_ENV}`);
  dispatch(onVerify({ auth: false, isLoading: false }));
  dispatch(onLoading(false));
};

const checkToken = () => {
  const token = window.localStorage.getItem(
    `authen-token-${process.env.REACT_APP_ENV}`,
  );
  const decode = decodeToken(token);
  return (dispatch) => {
    if (token) {
      dispatch(onVerify({ auth: true, isLoading: true }));
      dispatch(onLoading(true));
      fetch({
        method: 'get',
        url: '/users',
      }).then(() => {
        // console.log('check auth');
        dispatch(onVerify({ auth: true, isLoading: true }));
        dispatch(onLogin(decode.name,
          decode.permission,
          decode.userLevel,
          decode?.companyName || 'RTT Express',
          decode.roleLevel,
          decode?.companyId || '',
          decode?.hubId || '',
          decode?.agencyId || '',
          decode?.userId,
          decode?.isCustomer || false,
          decode.isFirstLogin,
          decode?.agencyCode || '',
          decode?.hubCode || '',
          decode?.hubPhoneNo || ''));
        dispatch(onLoading(false));
        dispatch(onVerify({ auth: true, isLoading: false }));
      })
        .catch((e) => console.log(e));
      // dispatch(onVerify({ auth: true, isLoading: false }));
    } else {
      dispatch(onVerify({ auth: false, isLoading: false }));
      dispatch(onLogout());
    }
  };
};
//  getNotification
const getNotification = () => (dispatch) => {
  dispatch(onLoading(true));

  return new Promise((resolve, reject) => {
    fetch({
      method: 'get',
      url: '/users/me/notification',
    })
      .then(async (res) => {
        if (res.data.success) {
          dispatch(setNotification(res.data.data));
          dispatch(onLoading(false));
          resolve(true);
        } else {
          dispatch(onLoading(false));
          reject(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
};

// to reducers
const onVerify = (action) => ({
  type: 'VERIFY',
  auth: action.auth,
  isLoading: action.isLoading,
});

const onLogin = (name, permission, userLevel, company, roleLevel, companyId, hubId, agencyId, userId, isCustomer, isFirstLogin,
  agencyCode, hubCode, hubPhoneNo) => ({
    type: 'LOGIN',
    name,
    permission,
    userLevel,
    company,
    roleLevel,
    companyId,
    hubId,
    agencyId,
    userId,
    isCustomer,
    isFirstLogin,
    agencyCode,
    hubCode,
    hubPhoneNo,
  });

const onLogout = () => ({
  type: 'LOGOUT',
  name: '',
});

const onLoading = (action) => ({
  type: 'LOADING',
  isLoading: action,
});

const setNotification = (action) => ({
  type: 'SET_NOTIFICATION',
  notificationCount: action,
});

export default {
  toLogin,
  toLogout,
  checkToken,
  updateFirstPassword,
  getNotification,
  onLogout
};
