import {
  Form,
  Input,
  Button,
  message,
  Layout,
  Col,
  Row,
  Spin,
  Card,
} from 'antd';
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import allAction from '../app/actions/index';

// const endpoint = process.env[`REACT_APP_ENDPOINT_${process.env.REACT_APP_ENV}`];

const { Content } = Layout;

const Login = (props) => {
  const { isLoading } = useSelector((state) => state.mainReducer);

  const [usernameChk, setUsernameChk] = useState('');

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  // const [socket, setSocket] = useState(null);

  // useEffect(() => {
  //   let newSocket = null;
  //   newSocket = io(endpoint.replace('/api', ''));

  //   console.log('Socket io start:', newSocket);
  //   setSocket(newSocket);

  //   return () => newSocket?.close();
  // }, [setSocket]);

  // useEffect(() => {
  //   const messageListener = (msg) => {
  //     console.log(msg.txt, msg.msg);
  //   };
  //   socket?.on('debug', messageListener);
  //   return () => {
  //     socket?.off('debug', messageListener);
  //   };
  // }, [socket]);

  const onFinish = (values) => {
    dispatch(allAction.authenAction.toLogin(values.username, values.password))
      .then(() => {
        message.success('Login Success!');
      })
      .catch((e) => message.error(e.message));
  };

  function isInputDouble(e) {
    // const { value } = e.target;
    // var ch = String.fromCharCode(e.which);
    // console.log('isInputDouble', value);
    // if (!/[0-9.]/.test(ch)) {
    //   e.preventDefault();
    // }
  }

  // useEffect(() => {
  //   console.log('username: ', form.getFieldValue('username'));
  //   // form.setFieldsValue({
  //   //   username: 'xxx',
  //   // });
  // }, [usernameChk]);

  const onChangePassword = (e) => {
    const { value } = e.target;
    form.setFieldsValue({
      password: value.replace(
        /[^A-Za-z\d|^~`! @#$%^&*()_\-+={[}\]\|\\:;"'<,>.?/]/g,
        ''
      ),
    });
  };

  const onChangeUsername = (e) => {
    e.preventDefault()
    const { value } = e.target;
    form.setFieldsValue({
      username: value.replace(
        /[^A-Za-z\d|^@+_.\-]/g,
        ''
      ),
    });
  };

  return (
    <>
      <Spin
        style={{ verticalAlign: 'middle', minHeight: '80vh' }}
        spinning={isLoading}
        tip="Loading..."
      >
        <Layout style={{ minHeight: '100vh', backgroundColor: ' #224b99' }}>
          <Content style={{ overflow: 'hidden' }}>
            <Row
              gutter={24}
              justify="center"
              align="middle"
              style={{ minHeight: '100vh' }}
            >
              <Col lg={{ span: 8 }} md={{ span: 10 }} xs={{ span: 20 }}>
                <Card style={{ borderRadius: 15 }}>
                  <Form
                    layout="vertical"
                    name="login"
                    onFinish={onFinish}
                    form={form}
                  >
                    <h1 style={{ textAlign: 'center' }}>{t('login')}</h1>
                    <Form.Item
                      value={"sss"}
                      label={t('username_or_phone_no')}
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: 'Please input username or phone number!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (/[^A-Za-z\d|^@+_.\-]/g.test(value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  'Incorrect characters.'
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Please input your username!',
                      //   },
                      // ]}
                    >
                      <Input
                        // onChange={onChangeUsername}
                        style={{ borderRadius: 5 }}
                        // onKeyPress={(event) => {
                        //   if (event.key === 'Enter') {
                        //     // onHandleSaveDiscount(text_discount);
                        //   } else {
                        //     isInputDouble(event);
                        //   }
                        // }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('password')}
                      style={{marginTop : -10}}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (/[^A-Za-z\d|^~`!@#$%^&*()_\-+={[}\]\|\\:;"'<,>.?/]/g.test(value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  'Incorrect characters.'
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password 
                        // onChange={onChangePassword}
                        style={{ borderRadius: 5 }} 
                        
                      />
                    </Form.Item>
                    <Row gutter={[24, 24]}>
                      {/* <Col xs={24}>
                        <a style={{ float: 'right', color: '#000' }} href="">
                          {t('forget-pass')}
                        </a>
                      </Col> */}
                      <Col xs={24}>
                        <Form.Item>
                          <Button
                            style={{ borderRadius: 5 , marginTop: 3 }}
                            type="primary"
                            htmlType="submit"
                            block
                          >
                            {t('submit')}
                          </Button>
                        </Form.Item>
                      </Col>
                    
                    
                    </Row>
                    <Row
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      RTT Express Version 0.4.9
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Spin>
    </>
  );
};

export default Login;
