import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import allAction from '../app/actions';

const endpoint = process.env[`REACT_APP_ENDPOINT_${process.env.REACT_APP_ENV}`];

// const { SubMenu } = Menu;
// const { Header, Content, Sider } = Layout;

const SocketIOConnect = () => {

  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);

  // const { i18n } = useTranslation();

  useEffect(() => {
    let newSocket = null;
    
    let endpointURL = endpoint.replace('/api', '')

    if(process.env.REACT_APP_ENV == "PROD"){
      endpointURL = `${endpointURL}:8080`
    }

    console.log('Socket io start: (URL) ', endpointURL)

    newSocket = io(endpointURL, { timeout: 1000, reconnectionDelay: 1000, reconnectionDelayMax: 2000 });

    console.log('Socket io start: (Socket)', newSocket);

    setSocket(newSocket);
    dispatch(allAction.mainAction.onSetSocket(newSocket));

    return () => newSocket?.close();
  }, [setSocket]);

  useEffect(() => {
    const onConnected = (msg) => {
      console.log('onConnected:', new Date() , socket);
    };
    const onDisConnected = (msg) => {
      console.log('onDisConnected:', new Date());
    };

    socket?.on('connect', onConnected);
    socket?.on('disconnect', onDisConnected);
    return () => {
      socket?.off('connect', onConnected);
      socket?.off('disconnect', onDisConnected);
    };
  }, [socket]);

  return null
};

export default SocketIOConnect;
