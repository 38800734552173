import authenAction from './authen';
import mainAction from './main';
import superAdminAction from './super-admin';
import companyAction from './company';
import customerAction from './customer';
import userManagementAction from './user-management';
import roleManagementAction from './role-management';
import settingAgencyAction from './setting-agency';
import settingLevelAction from './setting-level';
import settingBankAction from './setting-bank';
import agencyAction from './agency';
import hubAction from './hub';
import settingAreaAction from './setting-area';
import settingParcelTypeAction from './setting-parcel-type';
import settingTransportAction from './setting-transport';
import settingMorePriceAction from './setting-more-price';
import manageDriverAction from './manage-driver';
import storeCustomeAction from './store-custome';
import settingCustomeTypeAction from './setting-custome-type';
import storeSellAction from './store-sell';
import storeListSellAction from './store-list-sell';
import storeSettingAction from './store-setting';
import hubProductAction from './hub-product';
import hubListSellAction from './hub-list-sell';
import hubSaleOrder from './hub-sale-order';
import hubReturn from './hub-return';
import StoreSellOrder from './store-sell-order';
import storeWallet from './store-wallet';
import report from './report';
import reportBalanceAction from './report-balance';
import listSellAction from './list-sell';
import { kaiUtilsAction } from '../slice/kaiUtilsSlice';
import { orderItemImportAction } from '../slice/orderItemImportSlice';
import { dashboardAction } from '../slice/dashboardSlice';


const allAction = {
  authenAction,
  mainAction,
  superAdminAction,
  companyAction,
  customerAction,
  userManagementAction,
  roleManagementAction,
  settingAgencyAction,
  settingLevelAction,
  settingBankAction,
  agencyAction,
  hubAction,
  settingAreaAction,
  settingParcelTypeAction,
  settingTransportAction,
  settingMorePriceAction,
  manageDriverAction,
  storeCustomeAction,
  settingCustomeTypeAction,
  storeSellAction,
  storeListSellAction,
  storeSettingAction,
  hubProductAction,
  hubListSellAction,
  hubSaleOrder,
  StoreSellOrder,
  storeWallet,
  hubReturn,
  report,
  reportBalanceAction,
  listSellAction,
  orderItemImportAction,
  dashboardAction,
  kaiUtilsAction
};

export default allAction;
