import React, { useEffect, useState } from 'react';
import {
  Layout,
  Menu,
  Affix,
  Image,
  Modal,
  Divider,
  Spin,
  Avatar,
  Badge,
  Button,
  Col,
  Row,
} from 'antd';
import {
  useHistory,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import {
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SideBar from './SideBar';
import SocketIOConnect from './SocketIOConnect';
import routes from '../config/routes';
import Unauthrorized from '../pages/Unauthrorized';

import rttLogo from '../assets/rtt-logo.png';
import allAction from '../app/actions/index';
import NotFound from '../pages/NotFound';

import ExportPage from '../pages/Export';
import MpayIcon from '../assets/mPay.png';

import {
  walletApi
} from '../app/api/walletApi';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

import {
  useGetAgencyWalletQuery
} from './../app/api/walletApi';

import {
  dashboardApi
} from './../app/api/dashboardApi';

const PageLayout = () => {
  const {
    name, company, permission, verifyLoading,
    notificationCount, userLevel, hubId, agencyId, isCustomer,
  } = useSelector((state) => state.authenReducer);
  const { collapsedSider, isMobile, selectedHeaderKey } = useSelector(
    (state) => state.mainReducer,
  );
  // const { agencyWallet } = useSelector((state) => state.storeWalletReducer);



  const { socket } = useSelector((state) => state.mainReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { i18n, t } = useTranslation();

  const switchingLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('th');
    } else {
      i18n.changeLanguage('th');
    }
  };

  const {
    data: agencyWallet,
    // isFetching,
    // isLoading,
    // isSuccess, /* */
    // isError,
    // error,
    // refetch,
  } = useGetAgencyWalletQuery(!isCustomer && agencyId ? agencyId : undefined);

  // if (userLevel === 'HUB') {
  useEffect(() => {
    const update_trans_noti = (msg) => {
      dispatch(allAction.authenAction.getNotification());
    };
    if (hubId) {
      const channel = `update_trans_noti_${hubId}`;
      socket?.on(channel, update_trans_noti);
      return () => {
        const channel2 = `update_trans_noti_${hubId}`;
        socket?.off(channel2, update_trans_noti);
      };
    }
  }, [socket, hubId]);
  // }

  useEffect(() => {
    // console.log(' useEffect messageListener',socket);
    const messageListener = (msg) => {
      // console.log('messageListener', msg);
      if (msg) {
        if (agencyId && !isCustomer) {

          dispatch(walletApi.util.invalidateTags(['Wallet']))
          // setTimeout(() => {
          //   dispatch(allAction.storeWallet.getAgencyWallet(agencyId))
          //     .then()
          //     .catch((e) => message.error(e.message));
          // }, 1000);
        }
      }
    };

    if (socket && (agencyId && !isCustomer)) {
      const channel = `wallet_update_${agencyId}`;
      socket?.on(channel, messageListener);
    }

    return () => {
      if (socket && (agencyId && !isCustomer)) {
        const channel = `wallet_update_${agencyId}`;
        socket?.off(channel, messageListener);
      }
    };
  }, [socket, agencyId]);

  useEffect(() => {
    if (!isCustomer && agencyId) {
      dispatch(
        allAction.storeWallet.getAgencyWallet(
          agencyId,
        ),
      );
    }
  }, [agencyId]);

  useEffect(() => {
    dispatch(allAction.authenAction.getNotification());
    if (history?.location?.pathname === '/setting-account') {
      dispatch(allAction.mainAction.onSelectedKey([]));
      dispatch(allAction.mainAction.onSelectedHeaderKey('setting'));
    }
    dispatch(allAction.mainAction.onSelectedKey([history?.location?.pathname]));
  }, []);

  const confirmLogout = () => {
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <LogoutOutlined />,
      onOk() {
        dispatch(allAction.dashboardAction.reset())
        dispatch(dashboardApi.util.resetApiState())

        dispatch(allAction.authenAction.toLogout());
        dispatch(allAction.mainAction.onSelectedKey([]));
        dispatch(allAction.mainAction.onOpenKey([]));
        dispatch(allAction.storeWallet.clearAgencyWallet());
        history.push('/');


      },
      onCancel() {
        // console.log('Cancel');
      },
      okText: 'YES',
      cancelText: 'NO',
    });
  };

  const onCollapsedChange = (action) => {
    dispatch(allAction.mainAction.onCollapsed(action));
  };

  const paths = routes
    .map((route) => {
      const generateRegex = (path) => {
        const regexp = path
          .split('/')
          .map((p) => {
            if (p.startsWith(':')) return '\\S+';
            return p;
          })
          .join('/');
        return new RegExp(`${regexp}$`, 'g');
      };

      const build = (p) => ({
        path: p?.path,
        pageCode: p?.pageCode,
        render: p?.route?.component,
        regex: generateRegex(p?.path),
      });

      if (route.type === 'subMenu') {
        return route.children.map((children) => ({
          ...build(children),
          parent: route?.key,
        }));
      }
      return build(route);
    })
    .flat(1);

  return (
    <>
      <SocketIOConnect />
      {location.pathname.includes('/export/') ? (
        <div />
      ) : (
        <Spin
          spinning={verifyLoading}
          tip="Loading ..."
          style={{ verticalAlign: 'middle', minHeight: '80vh' }}
        >
          <Layout className="layout" style={{ minHeight: '100vh' }}>
            <Affix offsetTop={0.1} style={{ height: '100vh' }}>
              <Sider
                breakpoint="lg"
                collapsible
                theme="light"
                width={isMobile ? 256 : 256}
                // collapsedWidth={isMobile ? "0" : undefined}
                collapsedWidth={isMobile ? '0' : undefined}
                collapsed={collapsedSider || undefined}
                onCollapse={(collapsed) => {
                  onCollapsedChange(collapsed);
                }}
                onBreakpoint={(broken) => {
                  dispatch(allAction.mainAction.onMobile(broken));
                }}
                style={{
                  height: '100%',
                  zIndex: 100,
                  position: isMobile ? 'absolute' : 'static',
                  overflowY: isMobile ? null : 'scroll',
                  // backgroundColor: '#224b99'
                }}
                trigger={isMobile ? '' : null}
              >
                <Image style={{ padding: 10 }} src={rttLogo} preview={false} />
                <SideBar />
              </Sider>
            </Affix>
            <Layout className="site-layout">
              <Header
                className="site-layout-background"
                style={{
                  padding: 0,
                  background: '#fff',
                  borderWidth: 1,
                  display: 'flex',
                }}
              >
                {!isMobile && (
                  <span
                    style={{ paddingLeft: 20, cursor: 'pointer' }}
                    onClick={() => onCollapsedChange(!collapsedSider)}
                    aria-hidden="true"
                  >
                    {collapsedSider ? (
                      <MenuUnfoldOutlined style={{ color: '#224b99' }} />
                    ) : (
                      <MenuFoldOutlined style={{ color: '#224b99' }} />
                    )}
                  </span>
                )}

                {/* <Divider type="vertical" /> */}
                {/* <div style={{ width: "100%", float: 'right' }}>YYY</div> */}

                <div style={{
                  display: 'flex', flex: 1, justifyContent: 'flex-end', flexDirection: 'row',
                }}
                >
                  <div>
                    <Menu
                      mode="horizontal"
                      // style={{ float: 'right' }}
                      theme="light"
                      selectedKeys={selectedHeaderKey}
                      defaultSelectedKeys={selectedHeaderKey}
                      onSelect={(e) => {
                        // console.log('eee', e);
                        dispatch(allAction.mainAction.onSelectedKey([]));
                        dispatch(allAction.mainAction.onSelectedHeaderKey(e.key));
                      }}
                    >

                      {userLevel === 'AGN' && !isCustomer && (
                        <span>
                          <Row type="flex" justify="space-around" align="middle" style={{ float: 'left' }}>
                            <Col flex="auto" style={{ paddingRight: 5 }}>
                              <Row type="flex" justify="space-around" align="middle">
                                <Image
                                  preview={false}
                                  src={MpayIcon}
                                  width={28}
                                  onClick={() => window.open('/#/store-wallet/', '_blank')}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Row>
                            </Col>
                            <Col flex="auto" style={{ paddingLeft: 10 }}>
                              <div
                                style={{
                                  fontFamily: 'sans-serif',
                                  fontSize: '1.2em',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (!isCustomer) {
                                    dispatch(
                                      allAction.storeWallet.getAgencyWallet(agencyId),
                                    );
                                  }
                                }}
                              >
                                <div style={{ paddingRight: 5 }}>
                                  {numberWithCommas(agencyWallet?.wallet
                                    ? ((agencyWallet?.wallet || 0) - (agencyWallet?.reservePrice || 0)).toFixed(2)
                                    : 0)}
                                </div>
                                {' '}
                                <div>
                                  {' '}
                                  {t('thb')}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Divider type="vertical" />
                        </span>
                      )}
                      {/*  Badge notificationCount */}
                      {userLevel === 'HUB' && notificationCount > 0
                        && (
                          <Button
                            type="danger"
                            shape="round"
                            style={{ marginRight: 24, marginTop: 15 }}
                            onClick={() => history.push('/hub-product')}
                          >
                            {/* <Badge
                      className="site-badge-count-109"
                      count={notificationCount}
                      style={{ marginRight: 24 }}
                    /> */}
                            {notificationCount}
                          </Button>
                        )}

                      <span
                        style={{ paddingRight: 20, paddingLeft: 20 }}
                        onClick={() => {
                          switchingLanguage();
                          // confirmLogout();
                        }}
                        aria-hidden="true"
                      >
                        {company}
                      </span>
                      <Divider type="vertical" />

                    </Menu>
                  </div>
                  <div>
                    <Menu mode="horizontal">
                      {/* <Menu.Item key="mail" >
                        Navigation One
                    </Menu.Item>
                      <Menu.Item key="app" >
                        Navigation Two
                  </Menu.Item> */}
                      <SubMenu
                        type="setting"
                        title={(
                          <span>
                            <Avatar
                              style={{
                                color: '#fff',
                                backgroundColor: '#224b99',
                                marginRight: !isMobile ? 10 : 0,
                              }}
                            >
                              {name.substr(0, 1)}
                            </Avatar>
                            <span style={{ paddingLeft: 5 }}>{name}</span>
                          </span>
                        )}
                      >
                        <Menu.Item
                          key="setting"
                          icon={<SettingOutlined />}
                          onClick={() => history.push('/setting-account')}
                        >
                          <span>Setting</span>
                        </Menu.Item>
                        <Menu.Item
                          key="logout"
                          icon={<LogoutOutlined />}
                          onClick={() => confirmLogout()}
                        >
                          <span>Logout</span>
                        </Menu.Item>
                      </SubMenu>

                    </Menu>
                  </div>
                </div>
              </Header>
              <Content
                style={{
                  padding: 12,
                  minHeight: '50vh',
                }}
              >

                {/* {console.log('Layout Content')} */}
                <div className="site-layout-background">

                  {permission.length > 0 && (
                    <Switch>
                      {paths.map((p, idx) => {
                        if (p.path === '/setting-account') {
                          return (
                            <Route key={idx} exact path={p?.path} render={p?.render} />
                          );
                        }
                        if (p.pageCode === 'SSL' && permission.find((val) => ['HRT', 'HSO'].includes(val.pageCode))) {
                          return (
                            <Route key={idx} exact path={p?.path} render={p?.render} />
                          );
                        }
                        if (
                          permission.find((val) => val.pageCode === p.pageCode)
                            ?.isRead
                        ) {
                          return (
                            <Route key={idx} exact path={p?.path} render={p?.render} />
                          );
                        }
                        if (p?.path === '/dashboard') {
                          return <Route path={p?.path} component={NotFound} />;
                        }
                        return (
                          <Route key={idx} path={p?.path} component={Unauthrorized} />
                        );
                      })}

                      <Redirect from="*" to="/dashboard" />
                    </Switch>
                  )}
                </div>
              </Content>
              {/* <Footer style={{ textAlign: 'center', fontSize: '12px' }}>
              RTT Express Management 2021 Version 0.2.0
            </Footer> */}
            </Layout>
          </Layout>
        </Spin>
      )}

    </>
  );
  // }
};

function numberWithCommas(x) {
  if (!x) x = "0"
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default PageLayout;

