const initialState = {
  name: '',
  userId: '',
  userAuthId: '',
  userLevel: '',
  companyId: '',
  hubId: '',
  agencyId: '',
  company: '',
  permission: [],
  roleLevel: '',
  auth: false,
  verifyLoading: false,
  isCustomer: false,
  isFirstLogin: false,
  notificationCount: 0,
  agencyCode: '',
  hubCode: '',
  hubPhoneNo: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      console.log("reducer action", action)
      return {
        ...state,
        name: action.name,
        permission: action.permission,
        userLevel: action.userLevel,
        company: action.company,
        companyId: action.companyId,
        hubId: action.hubId,
        agencyId: action.agencyId,
        roleLevel: action.roleLevel,
        userId: action.userId,
        userAuthId: action.userId,
        isCustomer: action.isCustomer,
        isFirstLogin: action.isFirstLogin,
        agencyCode: action.agencyCode,
        hubCode: action.hubCode,
        hubPhoneNo: action.hubPhoneNo,
      };
    case 'LOGOUT':
      return {
        ...state,
        name: action.name,
      };
    case 'VERIFY':
      return {
        ...state,
        auth: action.auth,
        verifyLoading: action.isLoading,
      };
    case 'SET_NOTIFICATION':
      return {
        ...state,
        notificationCount: action.notificationCount,
      };
    default:
      return state;
  }
};

export default reducer;
