/* eslint-disable react/react-in-jsx-scope */
import {
  DashboardOutlined,
  SolutionOutlined,
  ClusterOutlined,
  FileTextOutlined,
  UserOutlined,
  SettingOutlined,
  BankOutlined,
  PayCircleOutlined,
  ToolOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import Dashboard from '../pages/dashboard/Dashboard';
import Test from '../test/Test';
import SuperAdmin from '../pages/super-admin/SuperAdmin';
import SuperAdminInput from '../pages/super-admin/SuperAdminInput';
import Customers from '../pages/customers/Customers';
import Agencys from '../pages/agencys/Agencys';
import AgencysInput from '../pages/agencys/AgencysInput';
import AgencysSetHub from '../pages/agencys/AgencysSetHub';
// import AgencysAddtition from '../pages/agencys/AgencysAddtition';
import Hubs from '../pages/hubs/Hubs';
import HubsInput from '../pages/hubs/HubInput';
import Products from '../pages/products/Products';
import Reports from '../pages/reports/Reports';
import Vehicles from '../pages/vehicles/Vehicles';
import Company from '../pages/manage-company/Company';
import CompanyInput from '../pages/manage-company/CompanyInput';
import Role from '../pages/user-managemant/role/Role';
import RoleInput from '../pages/user-managemant/role/RoleInput';
import User from '../pages/user-managemant/user/User';
import UserInput from '../pages/user-managemant/user/UserInput';
import SettingDegree from '../pages/settings/SettingDegree';
import SettingDegreeInput from '../pages/settings/SettingDegreeInput';
import SettingCategory from '../pages/settings/SettingCategory';
import SettingCategoryInput from '../pages/settings/SettingCategoryInput';
import SettingBankInput from '../pages/settings/SettingBankInput';
import SettingBank from '../pages/settings/SettingBank';
import SettingAccount from '../pages/setting-account/Setting';
import SettingTransport from '../pages/settings/SettingTransport';
import SettingArea from '../pages/settings/SettingArea';
import SettingParcelType from '../pages/settings/SettingParcelType';
import SettingMorePrice from '../pages/settings/SettingMorePrice';
import SettingMorePriceInput from '../pages/settings/SettingMorePriceInput';
import ManageDriver from '../pages/administration-hub/employee-driver/ManageDriver';
import ManageDriverInput from '../pages/administration-hub/employee-driver/ManageDriverInput';
import StoreCustome from '../pages/sale-system/store-customer/StoreCustomer';
import StoreSell from '../pages/sale-system/store-sell/StoreSell';
import StoreSell2 from '../pages/sale-system/store-sell2/StoreSell2';
import StoreSellImport from '../pages/sale-system/store-sell-import/StoreSellImport';
import StoreSellInput from '../pages/sale-system/store-sell/StoreSellInput';
import StoreCustomeInput from '../pages/sale-system/store-customer/StoreCustomeInput';
import SettingCustomeType from '../pages/settings/SettingCustomeType';
import SettingCustomeTypeInput from '../pages/settings/SettingCustomeTypeInput';
import StoreListSell from '../pages/sale-system/store-list-sell/StoreListSell';
import StoreSetting from '../pages/sale-system/store-setting/StoreSetting';
import HubProduct from '../pages/administration-hub/hub-product/HubProduct';
import HubListSell from '../pages/administration-hub/hub-list-sell/HubListSell';
import HubSaleOrder from '../pages/administration-hub/hub-sale-order/HubSaleOrder';
import ExportPdf from '../pages/Export';
import StoreSellOrder from '../pages/sale-system/store-sell-order/StoreSellOrder';
import HubReturn from '../pages/administration-hub/hub-return/HubReturn';
import StoreWallet from '../pages/sale-system/store-wallet/StoreWallet';
import PaymentCallback from '../pages/sale-system/store-wallet/PaymentCallback';
import PaymentCancel from '../pages/sale-system/store-wallet/PaymentCancel';
import ReportCod from '../pages/reports/report-cod/ReportCod';
import ReportBalance from '../pages/reports/report-balance/ReportBalance';
import ReportSell from '../pages/reports/report-sell/ReportSell';
import ReportSellDealer from '../pages/reports/report-sell-dealer/ReportSellDealer';
import ListSell from '../pages/list-sell/ListSell';
import NotFound from '../pages/NotFound';

const routes = [
  // example Type manu
  {
    path: '/dashboard',
    name: 'Dashboard',
    type: 'menu',
    disable: false,
    pageCode: 'DAS',
    menu: {
      icon: <DashboardOutlined />,
      render: 'menu-dashboard',
    },
    route: { component: (props) => <Dashboard {...props} pageCode="DAS" /> },
  },
  {
    path: '/super-admin',
    name: 'SuperAdmin',
    pageCode: 'SUA',
    type: 'menu',
    disable: false,
    menu: {
      icon: <ToolOutlined />,
      render: 'menu-super-admin',
    },
    route: { component: (props) => <SuperAdmin {...props} pageCode="SUA" /> },
  },
  {
    path: '/super-admin/:userId',
    pageCode: 'SUA',
    type: 'route',
    route: {
      component: (props) => <SuperAdminInput {...props} pageCode="SUA" />,
    },
  },
  {
    path: '/company',
    name: 'CompanyManagement',
    type: 'menu',
    disable: false,
    pageCode: 'COM',
    menu: {
      icon: <BankOutlined />,
      render: 'menu-company-management',
    },
    route: { component: (props) => <Company {...props} pageCode="COM" /> },
  },
  {
    path: '/company/:companyId',
    pageCode: 'COM',
    type: 'route',
    disable: 0,
    route: {
      component: (props) => <CompanyInput {...props} pageCode="COM" />,
    },
  },
  {
    key: 'user-management',
    type: 'subMenu',
    disable: false,
    menu: { icon: <UserOutlined />, render: 'menu-user-management' },
    children: [
      {
        type: 'menu',
        disable: false,
        path: '/user-management/user',
        pageCode: 'USE',
        menu: { render: 'menu-user' },
        route: { component: (props) => <User {...props} pageCode="USE" /> },
      },
      {
        type: 'menu',
        disable: false,
        path: '/user-management/permission',
        pageCode: 'PER',
        menu: { render: 'menu-role' },
        route: {
          component: (props) => <Role {...props} pageCode="PER" />,
        },
      },
      {
        type: 'menu',
        path: '/user-management/setting',
        pageCode: 'SETTING',
        menu: { render: <>ตั้งค่าเเผนก</> },
        route: { component: (props) => <Test {...props} pageCode="SETTING" /> },
      },
    ],
  },
  {
    path: '/user-management/user/:userId',
    type: 'route',
    pageCode: 'USE',
    route: {
      component: (props) => <UserInput {...props} pageCode="USE" />,
    },
  },
  {
    path: '/user-management/permission/:roleId',
    type: 'route',
    pageCode: 'PER',
    route: {
      component: (props) => <RoleInput {...props} pageCode="PER" />,
    },
  },
  {
    key: 'setting',
    type: 'subMenu',
    disable: false,
    menu: { icon: <SettingOutlined />, render: 'menu-setting' },
    children: [
      {
        type: 'menu',
        disable: false,
        path: '/setting-transport',
        pageCode: 'STR',
        menu: { render: 'menu-setting-transport' },
        route: {
          component: (props) => <SettingTransport {...props} pageCode="STR" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/setting-area',
        pageCode: 'SAR',
        menu: { render: 'menu-setting-area' },
        route: {
          component: (props) => <SettingArea {...props} pageCode="SAR" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/setting-supplies',
        pageCode: 'SSU',
        menu: { render: 'menu-setting-supplies' },
        route: {
          component: (props) => <SettingParcelType {...props} pageCode="SSU" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/setting-agency',
        pageCode: 'SAG',
        menu: { render: 'menu-setting-agency' },
        route: {
          component: (props) => <SettingCategory {...props} pageCode="SAG" />,
        },
      },
      {
        path: '/setting-agency/:agencyTypeId',
        name: 'Setting Category Create',
        type: 'route',
        pageCode: 'SAG',
        route: {
          component: (props) => (
            <SettingCategoryInput {...props} pageCode="SAG" />
          ),
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/setting-level',
        pageCode: 'SAD',
        menu: { render: 'menu-setting-agency-discount' },
        route: {
          component: (props) => <SettingDegree {...props} pageCode="SAD" />,
        },
      },
      {
        path: '/setting-level/:agencyLevelId',
        name: 'Setting Discount Create',
        type: 'route',
        pageCode: 'SAD',
        route: {
          component: (props) => (
            <SettingDegreeInput {...props} pageCode="SAD" />
          ),
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/setting-customer-type',
        pageCode: 'SCT',
        menu: { render: 'menu-setting-customer-type' },
        route: {
          component: (props) => (
            <SettingCustomeType {...props} pageCode="SCT" />
          ),
        },
      },
      {
        path: '/setting-customer-type/:customerTypeId',
        name: 'Setting Customer Type Create',
        type: 'route',
        pageCode: 'SCT',
        route: {
          component: (props) => (
            <SettingCustomeTypeInput {...props} pageCode="SCT" />
          ),
        },
      },
      // {
      //   type: 'menu',
      //   disable: false,
      //   path: '/setting-more-price',
      //   pageCode: 'SMP',
      //   menu: { render: 'menu-setting-more-price' },
      //   route: {
      //     component: (props) => <SettingMorePrice {...props} pageCode="SMP" />,
      //   },
      // },
      // {
      //   path: '/setting-more-price/:settingMorePriceId',
      //   type: 'route',
      //   pageCode: 'SMP',
      //   route: {
      //     component: (props) => (
      //       <SettingMorePriceInput {...props} pageCode="SMP" />
      //     ),
      //   },
      // },
      {
        type: 'menu',
        disable: false,
        path: '/setting-bank',
        pageCode: 'SBA',
        menu: { render: 'menu-setting-bank' },
        route: {
          component: (props) => <SettingBank {...props} pageCode="SBA" />,
        },
      },
      {
        path: '/setting-bank/:agencyBankId',
        name: 'Setting Bank Create',
        type: 'route',
        pageCode: 'SAD',
        route: {
          component: (props) => <SettingBankInput {...props} pageCode="SBA" />,
        },
      },
    ],
  },
  {
    path: '/hub-management',
    name: 'HubManagement',
    type: 'menu',
    disable: false,
    pageCode: 'HUB',
    menu: {
      icon: <ClusterOutlined />,
      render: 'menu-hub-management',
    },
    route: { component: (props) => <Hubs {...props} pageCode="HUB" /> },
  },
  {
    path: '/hub-management/:hubId',
    name: 'HubManagement Detail',
    type: 'route',
    pageCode: 'HUB',
    route: {
      component: (props) => <HubsInput {...props} pageCode="HUB" />,
    },
  },
  {
    key: 'hub-admin',
    type: 'subMenu',
    disable: false,
    menu: { icon: <SolutionOutlined />, render: 'menu-hub-admin' },
    children: [
      {
        type: 'menu',
        disable: false,
        path: '/hub-employee',
        pageCode: 'HEM',
        menu: { render: 'menu-hub-employee' },
        route: {
          component: (props) => <ManageDriver {...props} pageCode="HEM" />,
        },
      },
      {
        path: '/hub-employee/:driverId',
        type: 'route',
        pageCode: 'HEM',
        route: {
          component: (props) => <ManageDriverInput {...props} pageCode="HEM" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/hub-product',
        pageCode: 'HPR',
        menu: { render: 'menu-hub-product' },
        route: {
          component: (props) => <HubProduct {...props} pageCode="HPR" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/hub-return',
        pageCode: 'HRT',
        menu: { render: 'menu-hub-return' },
        route: {
          component: (props) => <HubReturn {...props} pageCode="HRT" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/hub-sale-order',
        pageCode: 'HSO',
        menu: { render: 'menu-hub-sale-order' },
        route: {
          component: (props) => <HubSaleOrder {...props} pageCode="HSO" />,
        },
      },
      // {
      //   type: 'menu',
      //   disable: false,
      //   path: '/hub-list-sell',
      //   pageCode: 'HLS',
      //   menu: { render: 'menu-hub-list-sell' },
      //   route: {
      //     component: (props) => <HubListSell {...props} pageCode="HLS" />,
      //   },
      // },
    ],
  },

  {
    path: '/agency-management',
    name: 'AgencyManagement',
    type: 'menu',
    disable: false,
    pageCode: 'AGN',
    menu: {
      icon: <ClusterOutlined />,
      render: 'menu-agency-management',
    },
    route: { component: (props) => <Agencys {...props} pageCode="AGN" /> },
  },
  {
    path: '/agency-management/:agencyId',
    name: 'AgencyManagement Detail',
    pageCode: 'AGN',
    type: 'route',
    route: {
      component: (props) => <AgencysInput {...props} pageCode="AGN" />,
    },
  },
  {
    path: '/agency-management-sethub',
    name: 'AgencyManagement Set Hub',
    pageCode: 'AGN',
    type: 'route',
    route: {
      component: (props) => <AgencysSetHub {...props} pageCode="AGN" />,
    },
  },
  {
    path: '/test-list',
    name: 'TestListSell',
    type: 'route',
    disable: false,
    pageCode: 'LIS',
    menu: {
      icon: <ProfileOutlined />,
      render: 'test',
    },
    route: { component: (props) => <Test {...props} pageCode="LIS" /> },
  },
  {
    key: 'sellstore',
    type: 'subMenu',
    disable: false,
    menu: { icon: <PayCircleOutlined />, render: 'menu-sellstore' },
    children: [
      {
        type: 'menu',
        disable: false,
        path: '/store-sell',
        pageCode: 'SSL',
        menu: { render: 'menu-store-sell' },
        route: {
          component: (props) => <StoreSell {...props} pageCode="SSL" />,
        },
      },
      // {
      //   type: 'menu',
      //   disable: false,
      //   path: '/store-sell2',
      //   pageCode: 'SSL',
      //   menu: { render: 'menu-store-sell2' },
      //   route: {
      //     component: (props) => <StoreSell2 {...props} pageCode="SSL" />,
      //   },
      // },
      {
        type: 'menu',
        // disable: /rtt-qa-ttmi/.test(`${window.location.href}`),
        disable: false,
        path: '/store-sell-import',
        pageCode: 'SSL',
        SSI: true,
        menu: { render: 'menu-store-sell-import' },
        route: {
          component: (props) => <StoreSellImport {...props} pageCode="SSL" />,
        },
      },
      {
        path: '/store-sell/:orderId',
        type: 'route',
        pageCode: 'SSL',
        route: {
          component: (props) => <StoreSellInput {...props} pageCode="SSL" />,
        },
      },
      {
        path: '/store-sell/:orderId/edit',
        type: 'route',
        pageCode: 'SSL',
        route: {
          component: (props) => <StoreSellInput {...props} pageCode="SSL" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/store-customer',
        pageCode: 'SCU',
        menu: { render: 'menu-store-customer' },
        route: {
          component: (props) => <StoreCustome {...props} pageCode="SCU" />,
        },
      },
      {
        path: '/store-customer/:storeCustomerId',
        type: 'route',
        pageCode: 'SCU',
        route: {
          component: (props) => <StoreCustomeInput {...props} pageCode="SCU" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/store-setting',
        pageCode: 'SST',
        menu: { render: 'menu-store-setting' },
        route: {
          component: (props) => <StoreSetting {...props} pageCode="SST" />,
        },
      },

      // {
      //   type: 'menu',
      //   disable: false,
      //   path: '/store-list-sell',
      //   pageCode: 'SLS',
      //   menu: { render: 'menu-store-list-sell' },
      //   route: {
      //     component: (props) => <StoreListSell {...props} pageCode="SLS" />,
      //   },
      // },
      {
        type: 'menu',
        disable: false,
        path: '/store-wallet',
        pageCode: 'SWA',
        menu: { render: 'menu-store-wallet' },
        route: {
          component: (props) => <StoreWallet {...props} pageCode="SWA" />,
        },
      },
      {
        type: 'menu',
        disable: false,
        path: '/store-sale-order',
        pageCode: 'ASO',
        menu: { render: 'menu-store-sell-order' },
        route: {
          component: (props) => <StoreSellOrder {...props} pageCode="ASO" />,
        },
      },
      // {
      //   type: 'menu',
      //   disable: false,
      //   path: '/store-shipping',
      //   pageCode: 'SSP',
      //   menu: { render: 'menu-store-shipping' },
      //   route: {
      //     component: (props) => <Test {...props} pageCode="SSP" />,
      //   },
      // },
    ],
  },
  {
    path: '/list-sell',
    name: 'ListSell',
    type: 'menu',
    disable: false,
    pageCode: 'LIS',
    menu: {
      icon: <ProfileOutlined />,
      render: 'menu-list-sell',
    },
    route: { component: (props) => <ListSell {...props} pageCode="LIS" /> },
  },
  {
    key: 'report',
    type: 'subMenu',
    disable: false,
    menu: { icon: <FileTextOutlined />, render: 'menu-report' },
    children: [
      {
        type: 'menu',
        disable: false,
        path: '/report-cod',
        pageCode: 'RCO',
        menu: { render: 'menu-report-cod' },
        route: {
          component: (props) => <ReportCod {...props} pageCode="RCO" />,
        },
      },
      // {
      //   type: 'menu',
      //   disable: false,
      //   path: '/all-in-one',
      //   pageCode: 'AIO',
      //   menu: { render: 'menu-report-all-in-one' },
      //   route: {
      //     component: (props) => <NotFound {...props} pageCode="AIO" />,
      //   },
      // },
      {
        type: 'menu',
        disable: false,
        path: '/report-balance',
        pageCode: 'RBA',
        menu: { render: 'menu-report-balance' },
        route: {
          component: (props) => <ReportBalance {...props} pageCode="RBA" />,
        },
      },
      // {
      //   type: 'menu',
      //   disable: true,
      //   path: '/report-sell-dealer',
      //   pageCode: 'RSD',
      //   menu: { render: 'menu-report-sell-dealer' },
      //   route: {
      //     component: (props) => <ReportSellDealer {...props} pageCode="RSD" />,
      //   },
      // },
    ],
  },
  {
    path: '/setting-account',
    name: 'AgencyManagement Detail',
    type: 'route',
    route: {
      component: (props) => <SettingAccount {...props} />,
    },
  },
  {
    path: '/payment-callback',
    name: 'Payment Callback',
    type: 'route',
    disable: false,
    pageCode: 'SWA',
    route: {
      component: (props) => <PaymentCallback {...props} />,
    },
  },
  {
    path: '/payment-cancel',
    name: 'Payment Cancel',
    type: 'route',
    disable: false,
    pageCode: 'SWA',
    route: {
      component: (props) => <PaymentCancel {...props} />,
    },
  },
];

export default routes;
