import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import PageLayout from './layouts/Layout';
import LoginPage from './pages/Login';
import ExportPage from './pages/Export';
import FirstLoginPage from './pages/FirstLogin';
import { Layout } from 'antd';
import { Route } from 'react-router-dom';
import './css/App.css';

// import jwt from 'jsonwebtoken'

import allAction from './app/actions/index';
const App = () => {
  const { auth, isFirstLogin } = useSelector((state) => state.authenReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allAction.authenAction.checkToken());
  }, []);

  if (!auth) {
    return <LoginPage />;
  }
  if (isFirstLogin) {
    return <FirstLoginPage />;
  }
  return (
    <HashRouter>
      <Route exact path="/export/:orderId" component={ExportPage} />
      <PageLayout />
    </HashRouter>
  );
};

export default App;
