// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Form {\n  font-family: sans-serif;\n  font-size: 0.5em;\n} */\n\n.StoreSell_scroll__28EPS::-webkit-scrollbar {\n  width: 8px;\n  height: 0.1px;\n}\n\n/* Track */\n.StoreSell_scroll__28EPS::-webkit-scrollbar-track {\n  background: #a9aaac;\n}\n\n/* Handle */\n.StoreSell_scroll__28EPS::-webkit-scrollbar-thumb {\n  background: #224b99;\n  border-radius: 50px;\n}\n\n/* Handle on hover */\n.StoreSell_scroll__28EPS::-webkit-scrollbar-thumb:hover {\n  background: #224b99;\n}\n", "",{"version":3,"sources":["webpack://src/pages/sale-system/store-sell/StoreSell.module.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;EACE,UAAU;EACV,aAAa;AACf;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA,oBAAoB;AACpB;EACE,mBAAmB;AACrB","sourcesContent":["/* Form {\n  font-family: sans-serif;\n  font-size: 0.5em;\n} */\n\n.scroll::-webkit-scrollbar {\n  width: 8px;\n  height: 0.1px;\n}\n\n/* Track */\n.scroll::-webkit-scrollbar-track {\n  background: #a9aaac;\n}\n\n/* Handle */\n.scroll::-webkit-scrollbar-thumb {\n  background: #224b99;\n  border-radius: 50px;\n}\n\n/* Handle on hover */\n.scroll::-webkit-scrollbar-thumb:hover {\n  background: #224b99;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "StoreSell_scroll__28EPS"
};
export default ___CSS_LOADER_EXPORT___;
