import React, { useEffect } from 'react';
import { Menu, Badge } from 'antd';
import { useHistory, Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import routes from '../config/routes';

import allAction from '../app/actions/index';

const { SubMenu } = Menu;

const SideBar = () => {
  const { permission, isCustomer, userLevel , agencyId } = useSelector((state) => state.authenReducer);
  const {
    collapsedSider, selectedKey, openKey, isMobile,
  } = useSelector(
    (state) => state.mainReducer,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const listRoute = [];

  useEffect(() => {
    dispatch(allAction.mainAction.onSelectedKey([history?.location?.pathname]));
    handleOpenKey(history?.location?.pathname);
  }, []);

  useEffect(() => {
    handleOpenKey(history?.location?.pathname);
  }, [collapsedSider]);

  const onOpenChange = (keys) => {
    // console.log('eekey', keys[keys.length - 1]);
    dispatch(allAction.mainAction.onOpenKey([keys[keys.length - 1]]));
  };

  const handleOpenKey = (path) => {
    if (
      path === '/user-management/user'
      || path === '/user-management/permission'
      || path === '/user-management/setting'
    ) {
      dispatch(allAction.mainAction.onOpenKey(['user-management']));
    } else if (
      path === '/setting-transport'
      || path === '/setting-area'
      || path === '/setting-supplies'
      || path === '/setting-agency'
      || path === '/setting-level'
      || path === '/setting-bank'
      || path === '/setting-more-price'
      || path === '/setting-customer-type'
    ) {
      dispatch(allAction.mainAction.onOpenKey(['setting']));
    } else if (
      path === '/hub-employee'
      || path === '/hub-product'
      || path === '/hub-list-sell'
      || path === '/hub-return'
      || path === '/hub-sale-order'
    ) {
      dispatch(allAction.mainAction.onOpenKey(['hub-admin']));
    } else if (
      path === '/store-sell'
      || path === '/store-sell2'
      || path === '/store-setting'
      || path === '/store-wallet'
      || path === '/store-customer'
      || path === '/store-shipping'
      || path === '/store-list-sell'
      || path === '/store-sell/create'
      || path === '/store-sale-order'
      || path === '/store-sell-import'
    ) {
      dispatch(allAction.mainAction.onOpenKey(['sellstore']));
    } else if (
      path === '/report-sell'
      || path === '/report-cod'
      || path === '/report-balance'
      || path === '/all-in-one'
    ) {
      dispatch(allAction.mainAction.onOpenKey(['report']));
    } else {
      dispatch(allAction.mainAction.onOpenKey(['']));
    }
  };

  const filterMenuRoute = (route) => {
    for (const data of permission) {
      if (data?.pageCode === route?.pageCode) {
        if (data?.isRead) {
          //userLevel

          if (!(isCustomer && (route.pageCode === 'SCU' || route.pageCode === 'RBA'))
          && !(userLevel !== 'AGN' && (route?.SSI))) {
             return route;
            }
        }
      }
    }
  };

  const renderMenu = (route) => {
    const newRoute = filterMenuRoute(route);
    if (newRoute) {
      listRoute?.push(newRoute);
      return (
        <Menu.Item
          style={
            !collapsedSider
              ? {
                whiteSpace: 'normal',
                height: 'auto',
                margin: 0,
                fontSize: 16,
              }
              : {}
          }
          key={newRoute.path}
          disabled={newRoute.disable}
          icon={newRoute.menu?.icon}
          onClick={async () => {
            if (isMobile) {
              onCollapsedChange(true);
            }
          }}
        >
          <Link to={newRoute.path}>{t(newRoute.menu?.render)}</Link>
        </Menu.Item>
      );
    }
  };

  const onCollapsedChange = (action) => {
    dispatch(allAction.mainAction.onCollapsed(action));
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      inlineIndent={18}
      selectedKeys={selectedKey}
      defaultSelectedKeys={selectedKey}
      defaultOpenKeys={openKey}
      openKeys={openKey}
      onOpenChange={onOpenChange}
      onSelect={(e) => {
        dispatch(allAction.mainAction.onSelectedKey(e.key));
        dispatch(allAction.mainAction.onSelectedHeaderKey([]));
        // window.location.reload();
        handleOpenKey(e.key);
      }}
    >
      {
        routes.map((route) => {
          if (route?.type === 'subMenu') {
            if (
              route.children
                .filter((r) => r.type === 'menu')
                .map((r) => renderMenu(r))
                .some((val) => val)
            ) {
              return (
                <>
                  <SubMenu
                    style={{ margin: 0, fontSize: 16 }}
                    disabled={route?.disable}
                    key={route?.key}
                    icon={route?.menu?.icon}
                    title={t(route?.menu?.render)}
                  >
                    {route.children
                      .filter((r) => r.type === 'menu')
                      .map((r) => renderMenu(r))}
                  </SubMenu>
                </>
              );
            }
          }
          if (route?.type === 'menu') return renderMenu(route);
          return <></>;
        })
      }
    </Menu>
  );
};

export default SideBar;
